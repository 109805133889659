import { Plugin } from 'js/viewer/plugins/Plugin';
import { LoadModelPlugin } from 'js/viewer/plugins/LoadModelPlugin';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export class DropzonePlugin extends Plugin{
	constructor(manager){
		super(manager);

		this.dependencies.add(LoadModelPlugin);

		this.dropzone = document.body
		this.dropper  = document.createElement('div');
		this.dropper.innerHTML = '<p>Drag and drop GLB/GLTF file to load it</p>';
		this.dropper.style.cssText = `
    		position:fixed;
    		top:0;
    		left:0;
    		width: 100%;
    		height: 100%;
    		background:gray;
    		overflow:hidden;
    		border: solid black 10px;
    		z-index:10000;
    		display: flex;
    		justify-content: center;
    		align-items: center;
    		font-size: 20px;
    		font-family: Courier New;
    		font-weight: bold;`;

    	document.body.appendChild(this.dropper);

		this.hoverCounter = 0;
	}

	get loadModelPlugin(){
		return this.manager.get(LoadModelPlugin);
	}

	async attach(){
		this.dropzone.addEventListener('drop', this.onDrop);
		this.dropzone.addEventListener('dragover', this.onDragOver);
		this.dropzone.addEventListener('dragenter', this.onDragEnter);
		this.dropzone.addEventListener('dragleave', this.onDragLeave);
		this.dropzone.addEventListener('dragend', this.onDragEnd);
		this.dropzone.addEventListener('dragexit', this.onDragEnd);

		this.loadModelPlugin.on('loading', this.onLoading);
		this.loadModelPlugin.on('loaded', this.onDragEnd);

		await super.attach();
	}

	async detach(){
		this.dropzone.removeEventListener('drop', this.onDrop);
		this.dropzone.removeEventListener('dragover', this.onDragOver);
		this.dropzone.removeEventListener('dragenter', this.onDragEnter);
		this.dropzone.removeEventListener('dragleave', this.onDragLeave);
		this.dropzone.removeEventListener('dragend', this.onDragEnd);
		this.dropzone.removeEventListener('dragexit', this.onDragEnd);

		this.loadModelPlugin.off('loading', this.onLoading);
		this.loadModelPlugin.off('loaded', this.onDragEnd);

		await super.attach();
	}
	onDrop = (e) => {
		e.preventDefault();
	    for (var i = 0; i < e.dataTransfer.files.length; i++) {
	    	this.loadFile(e.dataTransfer.files[i])
	    }

	    this.onDragEnd();
	}

	onLoading = () => {
		this.dropper.innerHTML = '<p>Please wait...</p>';
	}

	onDragEnd = () => {
	    this.hoverCounter = 0;
		this.dropper.style.display = 'none';
		this.dropper.innerHTML = '<p>Drag and drop GLB/GLTF file to load it</p>';
	}

	onDragOver = (e) => {
		e.preventDefault();
	}

	onDragEnter = (e) => {
		e.preventDefault();
		this.hoverCounter++;
		if(this.hoverCounter == 1){
			this.dropper.style.display = 'flex';
		}
	}

	onDragLeave = () => {
		this.hoverCounter--;
		if(this.hoverCounter == 0){
			this.dropper.style.display = 'none';
		}
	}

	
	loadFile(file){
		const reader = new FileReader();

		reader.addEventListener( 'progress', ( event )  => {
			const size = '(' + Math.floor( event.total / 1000 ) + ' KB)';
			const progress = Math.floor( ( event.loaded / event.total ) * 100 ) + '%';
			this.emit('progress', progress, size);
		});

		reader.addEventListener( 'load', ( event ) => {
			this.loadModelPlugin.parse(event.target.result);
		});

		reader.readAsArrayBuffer( file );
	}	
}