import {
	AmbientLight,
	DirectionalLight
} from 'three';

export class LightingHelper{

	static createLight(config, light){
		switch(config.type){
			case 'directional':
				return LightingHelper.createDirectionalLight(config, light);
			case 'ambient':
			default:
				return LightingHelper.createAmbientLight(config, light);
		}
	}

	static createAmbientLight(config, light){
		if(light instanceof AmbientLight){
			light.intensity = config.intensity;
			if(config.color)
				light.color.set(config.color);
		}
		else{
			light = new AmbientLight(config.color, config.intensity);
			light.name = config.name;
		}
		return light;
	}

	static createDirectionalLight(config, light){
		if(light instanceof DirectionalLight){
			light.intensity = config.intensity;
			if(config.color)
				light.color.set(config.color);
			if(config.position)
				light.position.set(config.position.x, config.position.y, config.position.z, )
		}
		else{
			light = new DirectionalLight(config.color, config.intensity);
			light.name = config.name;

			if(config.position){
				light.position.set(config.position.x, config.position.y, config.position.z)
			}

			light.castShadow = true;
			light.shadow.mapSize.width = 512; // default
			light.shadow.mapSize.height = 512; // default
			light.shadow.camera.near = 0.15; // default
			light.shadow.camera.far = 30; // default
			light.shadow.normalBias = 0.04;
		}
		return light;
	}
}