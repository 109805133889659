import { EventEmitter } from 'events';

export class Plugin extends EventEmitter{
	constructor(manager, name = null){
		super();
		this.manager 	= manager;
		this.viewer 	= manager.viewer;
		this.name 		= name !== null ? name : this.constructor.name;

		this._dependencies = new Set();
		this.update = this.update.bind(this);
	}

	get priority(){
		return 0;
	}

	get dependencies(){
		return this._dependencies;
	}

	requestRender = () => {
		this.viewer.requestRender();
	}

	attach(){}

	detach(){}

	async preload(){}

	async update(){}
}