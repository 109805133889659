import { extension, Loader } from 'libs';
import {
	TextureLoader as THREETextureLoader,
	CubeTextureLoader as THREECubeTextureLoader,
	sRGBEncoding
} from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { TextureHelper } from '../helpers';

export class TextureLoader extends Loader{
	constructor(basePath = null){
		super();
		this.basePath = basePath;
	}

	accepts(item){
		return typeof item == "string" || typeof item == "object";
	}

	async loadItem(item){
		let texture = await this.loadTexture(item);

		if(typeof item == "string")
			texture.name = item;
		else if(typeof item == "object"){
			texture.name = item.name;

			if(item.encoding)
				texture.encoding = TextureHelper.getEncoding(item.encoding);
			if(item.minFilter)
				texture.minFilter = TextureHelper.getMinFilter(item.minFilter);
			if(item.magFilter)
				texture.magFilter = TextureHelper.getMagFilter(item.magFilter);
			if(item.mapping)
				texture.mapping = TextureHelper.getMapping(item.mapping);
			if(item.format)
				texture.format = TextureHelper.getFormat(item.format);
		}

		return texture;
	}

	async loadTexture(item){
		switch(item.type){
			case "cube":
				return await this.loadCubeTexture(item);
			case "hdr":
				return await this.loadHDRTexture(item);
		}
		if(typeof item == "string"){
			switch(extension(item)){
				case "png":
				case "jpg":
				case "jpeg":
					return await this.loadSimpleTexture(item);
				case "hdr":
					return await this.loadHDRTexture(item);
			}
		}
		return await this.loadSimpleTexture(item);
	}

	loadSimpleTexture(item){
		return new Promise((resolve, reject) => {
			const loader = new THREETextureLoader();
			if(this.basePath)
				loader.setPath(this.basePath);

			loader.load(
				item,
				(texture) => {
					texture.name = item;
					texture.encoding = sRGBEncoding;
					resolve(texture)
				},
				(event) => {
					this.progresses.set(item, event.loaded/event.total | 0);
					this.emit('progress', this.progress);
				},
				(error) => reject(error)
			);
		});
	}

	loadCubeTexture(item){
		return new Promise((resolve, reject) => {
			let loader = new THREECubeTextureLoader();
			if(this.basePath)
				loader.setPath(this.basePath);

			let items = [
				item.name+'/px.png',
				item.name+'/nx.png',
				item.name+'/py.png',
				item.name+'/ny.png',
				item.name+'/pz.png',
				item.name+'/nz.png'
			];

			loader.load(
				items,
				(texture) => {
					resolve(texture)
				},
				(event) => {
					this.progresses.set(item, event.loaded/event.total | 0);
					this.emit('progress', this.progress);
				},
				(error) => reject(error)
			);
		});
	}

	loadHDRTexture(item){

		return new Promise((resolve, reject) => {
			const loader = new RGBELoader();
			if(this.basePath)
				loader.setPath(this.basePath);

			const url = typeof item == "string" ? item : item.name;

			loader.load(
				url,
				(texture) => {
					texture.name = item.name;
					resolve(texture)
				},
				(event) => {
					this.progresses.set(item, event.loaded/event.total | 0);
					this.emit('progress', this.progress);
				},
				(error) => reject(error)
			);
		});
	}
}
