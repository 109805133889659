import { extension, basename, Loader } from 'libs';
import { GLTFLoader as THREEGLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export class GLTFLoader extends Loader{
	constructor(basePath = null){
		super();

		this.basePath = basePath;
	}

	accepts(item){
		return typeof item == "string";
	}

	loadItem(item){
		return new Promise((resolve, reject) => {

			let loader = new THREEGLTFLoader();
			if(this.basePath){
				loader.setPath(this.basePath);
			}
			loader.load(
				item,
				(obj) => {
					if(obj.scene)
						resolve(obj.scene);
					else reject(new Error("Unrecognized GLB format: No scene found"))
				},
				(event) => {
					this.progresses.set(item, event.loaded/event.total || 0);
					this.emit('progress', this.progress);
				},
				(error) => reject(error)
			);
		});
	}
}