import {
	UVMapping,
	CubeReflectionMapping,
	CubeRefractionMapping,
	EquirectangularReflectionMapping,
	EquirectangularRefractionMapping,
	CubeUVReflectionMapping,
	CubeUVRefractionMapping,

	NearestFilter,
	LinearFilter,
	NearestMipmapNearestFilter,
	NearestMipmapLinearFilter,
	LinearMipmapNearestFilter,
	LinearMipmapLinearFilter,

	LinearEncoding,
	sRGBEncoding,
	GammaEncoding,
	RGBEEncoding,
	LogLuvEncoding,
	RGBM7Encoding,
	RGBM16Encoding,
	RGBDEncoding,
	BasicDepthPacking,
	RGBADepthPacking,

	AlphaFormat,
	RedFormat,
	RedIntegerFormat,
	RGFormat,
	RGIntegerFormat,
	RGBFormat,
	RGBIntegerFormat,
	RGBAFormat,
	RGBAIntegerFormat,
	LuminanceFormat,
	LuminanceAlphaFormat,
	RGBEFormat,
	DepthFormat,
	DepthStencilFormat
} from 'three';

export class TextureHelper{
	
	static getFormat(format){
		switch(format){
			case "alpha":
				return AlphaFormat;
			case "red":
				return RedFormat;
			case "rg":
				return RGFormat;
			case "rg-int":
				return RGIntegerFormat;
			case "rgb":
				return RGBFormat;
			case "rgb-int":
				return RGBIntegerFormat;
			case "rgba-int":
				return RGBAIntegerFormat;
			case "luminance":
				return LuminanceFormat;
			case "luminance-alpha":
				return LuminanceAlphaFormat;
			case "rgbe":
				return RGBEFormat;
			case "depth":
				return DepthFormat;
			case "depth-stencil":
				return DepthStencilFormat;
			default:
				return RGBAFormat;
		}
	}

	static getMapping(mapping){
		switch(mapping){
			case "cube-reflection":
				return CubeReflectionMapping;
			case "cube-refraction":
				return CubeRefractionMapping;
			case "equirectangular-reflection":
				return EquirectangularReflectionMapping;
			case "equirectangular-refraction":
				return EquirectangularRefractionMapping;
			case "cube-uv-reflection":
				return CubeUVReflectionMapping;
			case "cube-uv-refraction":
				return CubeUVRefractionMapping;
			default:
				return UVMapping;
		}
	}

	static getMagFilter(filter){
		switch(filter){
			case "nearest":
				return NearestFilter;
			default:
				return LinearFilter;
		}
	}

	static getMinFilter(filter){
		switch(filter){
			case "nearest":
				return NearestFilter;
			case "nearest-mipmap-nearest":
				return NearestMipmapNearestFilter;
			case "nearest-mipmap-linear":
				return NearestMipmapLinearFilter;
			case "linear-mipmap-nearest":
				return LinearMipmapNearestFilter;
			default:
				return LinearMipmapLinearFilter;
		}
	}

	static getEncoding(encoding){
		switch(encoding){
			case "gamma":
				return GammaEncoding;
			case "rgbe":
				return RGBEEncoding;
			case "logluv":
				return LogLuvEncoding;
			case "rgbm7":
				return RGBM7Encoding;
			case "rgbm16":
				return RGBM16Encoding;
			case "rgbd":
				return RGBDEncoding;
			case "basic-depth":
				return BasicDepthPacking;
			case "rgba-depth":
				return RGBADepthPacking;
			case "srgb":
				return sRGBEncoding;
			default:
				return LinearEncoding;
		}
	}

	static getEncodingFromValue(value){
		switch(value){
			case GammaEncoding:
				return "gamma";
			case RGBEEncoding:
				return "rgbe";
			case LogLuvEncoding:
				return "logluv";
			case RGBM7Encoding:
				return "rgbm7";
			case RGBM16Encoding:
				return "rgbm16";
			case RGBDEncoding:
				return "rgbd";
			case BasicDepthPacking:
				return "basic-depth";
			case RGBADepthPacking:
				return "rgba-depth";
			case sRGBEncoding:
				return "srgb";
			default:
				return "linear";
		}
	}
}
