import { Plugin } from './Plugin';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

export class LoadModelPlugin extends Plugin{
	constructor(manager){
		super(manager);

		this.dracoLoader = new DRACOLoader();
		this.dracoLoader.setDecoderPath('/gltf-draco/');

		this.ktx2Loader = new KTX2Loader();
		this.ktx2Loader.setTranscoderPath( '/basis/' );
		this.ktx2Loader.detectSupport(this.viewer.rendering.renderer);

		this.loader = new GLTFLoader();
		this.loader.setDRACOLoader( this.dracoLoader );
		this.loader.setKTX2Loader( this.ktx2Loader );
		this.loader.setMeshoptDecoder( MeshoptDecoder );

		this.previousModel = null;
	}

	sleep(duration = 100){
		return new Promise((res) => setTimeout(res, duration))
	}

	load(url, replace = true){
		return new Promise(async (res) => {
			this.emit('loading');
			await this.sleep();
			this.loader.load(url, ( result ) => {
				const scene = result.scene;
				scene.animations.push( ...result.animations );

				res(scene);

				this.emit('loaded', result);
			});
		});
	}

	parse(content, replace = true){
		return new Promise(async (res) => {
			this.emit('loading');
			await this.sleep();
			this.loader.parse( content, '', ( result ) => {
				const scene = result.scene;
				scene.animations.push( ...result.animations );

				res(scene);

				this.emit('loaded', result);
			});
		});
	}

}