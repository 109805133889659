import { Plugin } from './Plugin';

import * as dat from 'dat.gui';

export class DebugPlugin extends Plugin{
	constructor(manager){
		super(manager);

		this.folders = new Set();
		this.closed  = new Map();

		this._pluginClasses = [];
	}

	attach(){
		this.gui = new dat.GUI({
			width: 350
		});
		this.gui.domElement.parentElement.style.zIndex = 10000;
		this.gui.close();
	}

	detach(){
		this.uninstall();
		if(this.gui)
			this.gui.destroy()
	}

	uninstall(){

		this.syncClosed();

		this.folders.forEach(folder =>{
			this.gui.removeFolder(folder)
		});
		this.folders = new Set();
	}

	update(){
		this.refresh();
	}

	syncClosed(){
		this.folders.forEach(folder => {
			this.closed.set(folder.name, folder.closed)
		})
	}

	refresh = () => {
		this.uninstall();
		if(!this.gui) return;
		this._pluginClasses.forEach(pluginClass => {
			const plugin  = this.manager.get(pluginClass);
			if(plugin.debug){
				const name 	= plugin.name.replace('Plugin', '');
				const gui 	= this.gui.addFolder(name);
				plugin.debug(gui, this);

				if(gui.__controllers.length || Object.keys(gui.__folders).length){
					this.folders.add(gui);
					if(this.closed.has(gui.name)){
						this.closed.get(gui.name) ? gui.close() : gui.open();
					}
				}
				else{
					gui.parent.removeFolder(gui);
				}
			}
		});

		this.syncClosed();
	}

	debug = (...pluginClasses) => {
		this._pluginClasses = pluginClasses;
		this.refresh();
	}

	requestRender = () => {
		this.viewer.rendering.requestRender();
	}

	render = () => {
		this.stats.update();
	}
}


dat.GUI.prototype.addThreeColor = function(obj,varName, cb = () => {}){
    // threejs & dat.gui have color incompatible formats so we use a dummy data as target :
    var dummy={};
    // set dummy initial value :
    dummy[varName]=obj[varName].getHex(); 
    

    return this.addColor(dummy,varName).onChange((colorValue) => {
        obj[varName].set(colorValue);
        cb(colorValue);
    });
};
dat.GUI.prototype.addThreeUniformColor=function(material,uniformName,label){
    return this.addThreeColor(material.uniforms[uniformName],"value").name(label||uniformName);
};