import {
	NoToneMapping,
	LinearToneMapping,
	ReinhardToneMapping,
	CineonToneMapping,
	ACESFilmicToneMapping,
	CustomToneMapping
} from 'three';


export class RenderingHelper{

	static getToneMappingName(value){
		switch(value){
			case LinearToneMapping:
				return "Linear";
			case ReinhardToneMapping:
				return "Reinhard";
			case CineonToneMapping:
				return "Cineon";
			case ACESFilmicToneMapping:
				return "ACESFilmic";
			case CustomToneMapping:
				return "Custom";
		}
		return "None";
	}

	
	static getToneMappingValue(type){
		switch(type){
			case "Linear":
				return LinearToneMapping;
			case "Reinhard":
				return ReinhardToneMapping;
			case "Cineon":
				return CineonToneMapping;
			case "ACESFilmic":
				return ACESFilmicToneMapping;
			case "Custom":
				return CustomToneMapping;
		}
		return NoToneMapping;
	}

}