import { Plugin } from './Plugin';
import { LoadModelPlugin } from './LoadModelPlugin';

export class VariantsPlugin extends Plugin{
	constructor(manager){
		super(manager, 'VariantsPlugin');

		this.dependencies.add(LoadModelPlugin);

		this.scene 		= null;
		this.parser		= null;
		this.variants 	= [];
	}

	get loadModelPlugin(){
		return this.manager.get(LoadModelPlugin);
	}

	attach(){
		this.loadModelPlugin.on('loaded', this.onLoaded)
	}

	detach(){
		this.loadModelPlugin.off('loaded', this.onLoaded)
	}

	get variant(){ return this._variant; }
	set variant(variant){
		if(this._variant != variant){
			this._variant = variant;
			this.setVariant(this.scene, variant);
		}
	}

	onLoaded = (result) => {
		// Details of the KHR_materials_variants extension used here can be found below
		// https://github.com/KhronosGroup/glTF/tree/master/extensions/2.0/Khronos/KHR_materials_variants
		if(result.userData
		&& result.userData.gltfExtensions
		&& result.userData.gltfExtensions.hasOwnProperty('KHR_materials_variants')){
			const variantsExtension = result.userData.gltfExtensions[ 'KHR_materials_variants' ];

			this.variants = variantsExtension.variants;
			this.scene = result.scene;
			this.parser = result.parser;

			if(variantsExtension.variants && variantsExtension.variants.length){
				this.variant = variantsExtension.variants[0].name;
			}


		}
		else{
			this.variants = [];
			this.scene = result.scene;
			this.parser = null;
		}

		this.emit('loaded', this.variants);
	}

	setVariant(scene, variantName ) {


		if(this.scene && this.variants && this.variants.length){
			const variantIndex = this.variants.findIndex( ( v ) => v.name.includes( variantName ) );
			this.scene.traverse( async ( object ) => {
				if ( ! object.isMesh || ! object.userData.gltfExtensions ) return;

				const meshVariantDef = object.userData.gltfExtensions[ 'KHR_materials_variants' ];

				if ( ! meshVariantDef ) return;

				if ( ! object.userData.originalMaterial ) {

					object.userData.originalMaterial = object.material;

				}

				const mapping = meshVariantDef.mappings.find( ( mapping ) => mapping.variants.includes( variantIndex ) );

				if ( mapping ) {

					object.material = await this.parser.getDependency( 'material', mapping.material );
					this.parser.assignFinalMaterial(object);

				} else {

					object.material = object.userData.originalMaterial;

				}
				this.requestRender();
			} );
		}

	}

	debug(gui, debug){
		if(this.variants && this.variants.length > 0){
			gui.add(this, 'variant', Object.fromEntries(this.variants.map(variant => [variant.name, variant.name])))
		}
	}
}