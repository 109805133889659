import {PMREMGenerator} from "three/src/extras/PMREMGenerator";

export class PMREMHelper{
	static generateEquirectangular(renderer, texture){
		var pmremGenerator = new PMREMGenerator( renderer );
		let t = pmremGenerator.fromEquirectangular( texture ).texture;
		pmremGenerator.dispose();
		return t;
	}

	static generateCube(renderer, texture){
		var pmremGenerator = new PMREMGenerator( renderer );
		let t = pmremGenerator.fromCubemap( texture ).texture;
		pmremGenerator.dispose();
		return t;
	}

	static fromScene(renderer, scene){
		var pmremGenerator = new PMREMGenerator( renderer );
		let t = pmremGenerator.fromScene( scene ).texture;
		pmremGenerator.dispose();
		return t;
	}
}