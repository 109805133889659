import { Plugin } from 'js/viewer/plugins/Plugin';
import { LoadModelPlugin } from 'js/viewer/plugins/LoadModelPlugin';
import { LightingPlugin } from 'js/viewer/plugins/LightingPlugin';
import { GlobalsPlugin } from 'js/viewer/plugins/GlobalsPlugin';
import { TextureLoader } from 'js/viewer/loaders/TextureLoader';
import { APP_URL } from 'js/AppUrl'
import { Color } from 'three';


export class UrlParamsPlugin extends Plugin{
	constructor(manager){
		super(manager);

		this.dependencies.add(LoadModelPlugin);
		this.dependencies.add(LightingPlugin);
		this.dependencies.add(GlobalsPlugin);

		this.defaultConfig = {
			ambient:{
				intensity:1,
				color:0xffffff
			},
			gammaFactor:2,
			outputEncoding:'srgb',
			toneMapping:'None',
			toneMappingExposure:1,
			toneMappingWhitepoint:10,
			antialias:false
		};

		this.textureLoader = new TextureLoader();
		this.config = this.parseUrl();
	}

	get resourceDir(){ return `${APP_URL}resources/`; }
	get loadModelPlugin(){ return this.manager.get(LoadModelPlugin); }
	get lightingPlugin(){ return this.manager.get(LightingPlugin); }
	get globalsPlugin(){ return this.manager.get(GlobalsPlugin); }

	parseUrl(){
		this.params = new URLSearchParams(window.location.search);

		const config = JSON.parse(JSON.stringify(this.defaultConfig));

		if(this.params.has('model')){
			config.model = this.params.get('model');
		}
		if(this.params.has('envMap')){
			config.envMap = this.params.get('envMap');
		}
		if(this.params.has('ambientColor')){
			config.ambient.color = (new Color('#'+this.params.get('ambientColor'))).getHex();
		}
		if(this.params.has('ambientIntensity')){
			config.ambient.intensity = parseFloat(this.params.get('ambientIntensity'));
		}
		if(this.params.has('gammaFactor')){
			config.gammaFactor = parseFloat(this.params.get('gammaFactor'));
		}
		if(this.params.has('outputEncoding')){
			config.outputEncoding = this.params.get('outputEncoding');
		}
		if(this.params.has('toneMapping')){
			config.toneMapping = this.params.get('toneMapping');
		}
		if(this.params.has('toneMappingExposure')){
			config.toneMappingExposure = parseFloat(this.params.get('toneMappingExposure'));
		}
		if(this.params.has('toneMappingWhitepoint')){
			config.toneMappingWhitepoint = parseFloat(this.params.get('toneMappingWhitepoint'));
		}
		if(this.params.has('antialias')){
			config.antialias = this.params.get('antialias') == "1" || this.params.get('antialias') == "true";
		}

		return config;
	}

	async preload(){
		const promises = [];

		if(this.config.model){
			promises.push(this.loadModelPlugin.load(this.resourceDir + this.config.model));
		}

		if(this.config.envMap){
			promises.push(this.textureLoader.load(this.resourceDir + this.config.envMap));
		}

		await Promise.all(promises);

		if(this.config.envMap){
			this.globalsPlugin.envMap = this.textureLoader.get(this.resourceDir + this.config.envMap);
		}

		if(this.config.ambient.color || this.config.ambient.intensity){
			this.lightingPlugin.setLight({
				type:'ambient',
				name:'ambient',
				intensity: this.config.ambient.intensity,
				color:this.config.ambient.color
			});
		}


		this.globalsPlugin.outputEncoding = this.config.outputEncoding;
		this.globalsPlugin.toneMapping = this.config.toneMapping;
		this.globalsPlugin.gammaFactor = this.config.gammaFactor;
		this.globalsPlugin.toneMappingExposure = this.config.toneMappingExposure;
		this.globalsPlugin.toneMappingWhitepoint = this.config.toneMappingWhitepoint;
		this.globalsPlugin.antialias = this.config.antialias;
	}

	attach(){
		this.globalsPlugin.on('change', this.update);
		this.lightingPlugin.on('change', this.update);
	}

	detach(){
		this.globalsPlugin.off('change', this.update);
		this.lightingPlugin.off('change', this.update);
	}

	update = () => {
		const params = new URLSearchParams();

		let changed = false;

		const ambientLight = this.lightingPlugin.lights.get('ambient');
		if(ambientLight){
			const ambientColor = ambientLight.color.getHex();
			const ambientIntensity = ambientLight.intensity;

			if(ambientColor != this.defaultConfig.ambient.color){
				params.append('ambientColor', ambientColor.toString(16).toUpperCase());
				changed = true;
			}
			if(ambientIntensity != this.defaultConfig.ambient.intensity){
				params.append('ambientIntensity', ambientIntensity);
				changed = true;
			}
		}

		if(this.config.model){
			params.append('model', this.config.model);
		}

		if(this.config.envMap){
			params.append('envMap', this.config.envMap);
		}

		if(this.globalsPlugin.outputEncoding != this.defaultConfig.outputEncoding){
			params.append('outputEncoding', this.globalsPlugin.outputEncoding);
			changed = true;
		}
		if(this.globalsPlugin.gammaFactor != this.defaultConfig.gammaFactor){
			params.append('gammaFactor', this.globalsPlugin.gammaFactor);
			changed = true;
		}
		if(this.globalsPlugin.toneMapping != this.defaultConfig.toneMapping){
			params.append('toneMapping', this.globalsPlugin.toneMapping);
			changed = true;
		}
		if(this.globalsPlugin.toneMappingExposure != this.defaultConfig.toneMappingExposure){
			params.append('toneMappingExposure', this.globalsPlugin.toneMappingExposure);
			changed = true;
		}
		if(this.globalsPlugin.toneMappingWhitepoint != this.defaultConfig.toneMappingWhitepoint){
			params.append('toneMappingWhitepoint', this.globalsPlugin.toneMappingWhitepoint);
			changed = true;
		}
		if(this.globalsPlugin.antialias != this.defaultConfig.antialias){
			params.append('antialias', this.globalsPlugin.antialias);
			changed = true;
		}

		if(changed){
			if([...params.keys()].length){
				window.history.pushState({}, '', `?${params.toString()}`);
			}
			else{
				window.history.pushState({}, '', '//' + window.location.host + window.location.pathname);
			}
		}
		else{
			window.history.pushState({}, '', '//' + window.location.host + window.location.pathname);
		}
	}

}