import { Plugin } from './Plugin';
import { LightingHelper } from '../helpers';
import { Object3D } from 'three';

export class LightingPlugin extends Plugin{
	constructor(manager){
		super(manager, 'LightingPlugin');
		this.lights = new Map();

		this._visible = true;
	}

	get visible(){ return this._visible; }
	set visible(visible){
		this.lights.forEach(light => light.visible = visible);
	}

	setLight(config, pivot = null){
		let light = LightingHelper.createLight(config, this.lights.get(config.name));
		this.lights.set(light.name, light);
		this.viewer.rendering.scene.add(light);
		this.viewer.requestRender();
	}

	removeLight(name){
		if(this.lights.has(name)){
			let light = this.lights.get(name);
			this.lights.delete(name);
			this.viewer.rendering.scene.remove(light);
			this.viewer.requestRender();
		}
	}

	debug(gui, debug){

		this.lights.forEach((light, name) => {
			const f = gui.addFolder(name);
			f.add(light, 'intensity').min(0).max(3).step(0.01).onChange(this.onChange)
			f.addThreeColor(light, 'color', this.onChange)
		})
	}

	onChange = () => {
		this.requestRender();
		this.emit('change');
	}

}