import { EventEmitter } from 'events';
import { Rendering, Globals, GLTFLoader } from './';
import { PluginManager } from './plugins';
import { Viewport } from 'libs';

export class Viewer extends EventEmitter{
	constructor({
		canvas = null,
		plugins = [],
		resize = ({width,height}) => ({width, height})
	} = {}){
		super();

		this.rendering 		= new Rendering(canvas);
		this.viewport   	= new Viewport({sizer:this.domElement});
		this.plugins  		= new PluginManager(this, plugins);

		this.gltfLoader		= new GLTFLoader();

		this.resizeFn = resize;

		this.progress 	= 0;
		this.width 		= 1;
		this.height 	= 1;

		window["viewer"] = this;
	}

	get domElement(){
		return this.rendering.domElement;
	}

	async load(url, relative=true){
		this.progress = 0;
		this.emit('progress', 0);

		this.gltfLoader.add(url);
		await this.gltfLoader.load();
		const model = this.gltfLoader.get(url);

		//Free memory
		setTimeout(() => {
			this.gltfLoader.delete(url);
		}, 200);

		return model;
	}

	add(item){
		this.rendering.add(item);
	}

	remove(item){
		this.rendering.remove(item);
	}



	async update(){
		await this.plugins.update();
		this.rendering.compose();
		this.emit('update');
		this.requestRender();
	}

	requestRender = () => {
		this.rendering.requestRender();
	}

	resize = (width, height) => {

		if(this.domElement && (typeof width != "number" || typeof height != "number")){
			this.domElement.style.removeProperty("width");
			this.domElement.style.removeProperty("height");
			this.domElement.removeAttribute("width");
			this.domElement.removeAttribute("height");
			
	        let bounds = this.domElement.getBoundingClientRect();

	        width  = Math.round(bounds.width);
	        height = Math.round(bounds.height);
		}


		let {width:w, height:h} = this.resizeFn({width, height});

		if (w == 0 || h == 0) {return;}
		this.width 	= w;
		this.height = h;
		this.rendering.setSize(w, h);

		this.emit('resize');
	}

	async attach(){
		window.addEventListener('resize', this.resize);
		this.viewport.attach();
		await this.plugins.attach();
		this.gltfLoader.on('progress', this.onProgress);
	}

	async detach(){
		window.removeEventListener('resize', this.resize);
		this.viewport.detach();
		await this.plugins.detach();
		this.gltfLoader.off('progress', this.onProgress);
	}

	destroy(){
		this.stop();
		this.detach();
		this.rendering.destroy();
	}

	onProgress = () => {
		if(this.gltfLoader.progress != this.progress){
			this.progress = this.gltfLoader.progress;
			this.emit('progress', this.gltfLoader.progress);
		}
	}

	start(){
		this.rendering.start();
	}

	stop(){
		this.rendering.stop();
	}
}

