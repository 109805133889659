import { EventEmitter } from 'events';

function getTime(){
	return (window.performance && window.performance.now ? window.performance.now() : (new Date()).getTime()) / 1000;
}

export class Ticker extends EventEmitter{
	constructor({
			fps = +Infinity,
			benchmark = false
		} = {}){
		super();

		this.rendering 		= false;

		this.autoBenchmark 	= benchmark;
		this.running 		= false;
		this._startTime 	= 0;
		this._endTime 		= 0;
		this._currentFps 	= 0;
		this._minFps 		= +Infinity;
		this._maxFps 		= -Infinity;
		this._renderTime	= 0;
		this._framerate 	= 0;

		this._benchmark 		= false;
		this._rendered 			= false;
		this._renderRequested 	= false;

		this._averageFps 		= null;
		this._averageSamples 	= 5;
		this._targetFps 		= fps;
	}


	_preRender(){
		let time = getTime();
		this._deltaTime  = time - this._startTime; 
		this._framerate  = 1 / this._deltaTime;
		this._startTime  = time;
		let skip = this._framerate > 60;

		if(!skip){

			if(this._benchmark){
				this._maxFps 	  		= Math.max(this._maxFps, this._framerate);
				this._minFps 	  		= Math.min(this._minFps, this._framerate);

				this._averageFps = (this._averageFps * (this._averageSamples-1) + this._framerate) / this._averageSamples;
			}
		}
	}

	_postRender(){
		this._endTime 		= getTime();
		this._deltaTime 	= this._endTime - this.startTime; 
	}

	_render(){
		if(!this.rendering && !this._renderRequested && !this._benchmark) return;

		let time = getTime();
		let renderDelta = time - this._renderTime;
		let renderFps 	= 1/renderDelta;

		if(renderFps > this._targetFps && !this._renderRequested) return;

		this._renderDelta 		= renderDelta;
		this._renderTime 		= time;
		this._rendered	 		= true;
		this._renderRequested 	= false;
		this.emit('tick', this._renderDelta);
	}

	get renderDelta(){
		return this._renderDelta;
	}

	get currentFps(){
		return this._currentFps;
	}

	get maxFps(){
		return this._maxFps;
	}

	get minFps(){
		return this._minFps;
	}

	get averageFps(){
		return this._averageFps;
	}

	set targetFps(value){
		if(this._targetFps == value) return;

		this._targetFps = value;
		if(this.autoBenchmark)
			this.benchmark();
	}

	get targetFps(){
		return this._targetFps;
	}

	tick = () => {
		if(!this.running) return;
		requestAnimationFrame(this.tick);

		this._preRender();
		this._render();
		this._postRender();
	}

	requestRender = () => {
		this._renderRequested = true;
	}

	benchmark = () => {
		clearTimeout(this._benchmarkTimer);
		this._benchmark  = true;
		this._averageFps = this._framerate;
		this._benchmarkTimer = setTimeout(() => {
			this._benchmark = false;
			this.emit('benchmark');
		}, 5000);
	}

	start(){
		if(!this.running){
			let time = getTime();
			this._startTime 	= time;
			this._renderTime 	= time;
			this._endTime 		= time;
			this.running = true;
			this.tick();

			if(this.autoBenchmark){
				setTimeout(this.benchmark, 1000);
			}
		}
	}

	stop(){
		this.running = false;
	}
}