import { Object3D, Euler, Quaternion, Vector3, AxisHelper, MeshBasicMaterial } from 'three';
import { Model } from './Model';

const overrideMaterial = new MeshBasicMaterial({
	color:0x999999,
	opacity:0.75
})

overrideMaterial.transparent = true;

export class Entity extends Model{
	constructor(viewer, modelName, data, product, isUser = false){
		super(viewer, modelName, data);

		this.product 	= product;
		this.isUser 	= isUser;
		this.dependencies = new Set();
	}

	layout(){

		if(!this.data) return;
		
		if(this.data.hasOwnProperty('entity_scale')){
			this.wrapper.scale.set(
				this.data.entity_scale,
				this.data.entity_scale,
				this.data.entity_scale
			);
		}

		this.rotation.set(0,0,0);

		if(this.data.hasOwnProperty('entity_anchor')
		&& this.data.hasOwnProperty('scene_anchor')){

			this.layoutWrapper.position.set(
				-this.data.entity_anchor.position.x,
				-this.data.entity_anchor.position.y,
				-this.data.entity_anchor.position.z
			);

			this.position.set(
				this.data.scene_anchor.position.x,
				this.data.scene_anchor.position.y,
				this.data.scene_anchor.position.z,
			);

			const entityDir = new Vector3().copy(this.data.entity_anchor.direction);
			const sceneDir 	= new Vector3().copy(this.data.scene_anchor.direction).negate();

			if(entityDir.length() != 0 && sceneDir.length() != 0){
				this.quaternion.setFromUnitVectors(entityDir, sceneDir);
			}
		
			if(this.data.hasOwnProperty('entity_rotation')){
				this.rotateOnAxis(this.data.entity_anchor.direction, this.data.entity_rotation * Math.PI / 180);
			}
		}

		this.traverse(this.updateGhosts)
	}

	updateGhosts = obj => {
		if(obj.isMesh && obj.material){
			if(!this.isUser){
				obj.oldMaterial = obj.material;
				obj.material = overrideMaterial;
			}
			else if(obj.oldMaterial){
				obj.material = obj.oldMaterial;
			}
			// obj.material.emissive.set(this.isUser ? 0x000000 : 0xffffff)
			// obj.material.emissiveIntensity = 0.75;
			obj.material.needsUpdate = true;
		}
	}

	has(dependencyId){
		return [...this.dependencies].find(dep => dependencyId == dep.product.id)
	}

	addDependencies(dependencies){
		const previous = [...this.dependencies];

		const newDependencies = dependencies.filter(dep => !previous.find(prev => prev.product.id == dep.product.id))
		const toRemove = previous.filter(prev => !dependencies.find(dep => prev.product.id == dep.product.id))

		const postLoaded = () => {
			toRemove.forEach(prev => this.removeDependency(prev));
			newDependencies.forEach(dep => this.addDependency(dep));
		}
		newDependencies.forEach(dep => {
			if(dep.loaded){
				postLoaded();
			}
			else dep.addEventListener('loaded', postLoaded);
		})
	}

	addDependency(entityDependency){
		this.dependencies.add(entityDependency);
		this.wrapper.add(entityDependency);
		entityDependency.traverse(this.updateGhosts);
	}

	removeDependency(entityDependency){
		this.dependencies.delete(entityDependency);
		this.wrapper.remove(entityDependency);

		entityDependency.freeMemory();
	}

	clearDependencies(){
		this.dependencies.forEach(dependency => {
			this.removeDependency(dependency);
		})
	}
}