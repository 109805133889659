import {PerspectiveCamera, Scene, WebGLRenderer, Uncharted2ToneMapping, PCFSoftShadowMap, GammaEncoding, Color } from 'three';
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {TexturePass} from "three/examples/jsm/postprocessing/TexturePass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {ClearPass} from "three/examples/jsm/postprocessing/ClearPass";
import {CopyShader} from "three/examples/jsm/shaders/CopyShader";
// import {Composition} from "../postprocessing";

import { Ticker } from './Ticker';

export class Rendering {
    constructor(canvas = null) {

        const cfg = {
            powerPreference: "high-performance",
            alpha:true,
            antialias:true
        };

        if(canvas) cfg.canvas = canvas;

        this.renderer = new WebGLRenderer(cfg);

        this.renderer.setPixelRatio(Math.min(1, window.devicePixelRatio));
        this.renderer.setClearColor(0xCCCCCC);
        this.renderer.setClearAlpha(1);

        this.composer = new EffectComposer(this.renderer);

        this.scene = new Scene();

        this.camera = new PerspectiveCamera(
            50,
            16/9,
            0.01,
            1000
        );

        this.rendering  = false;
        this.time       = 0;

        this.passes         = new Map();
        this.customPasses   = new Set();
        this.disableRenderPass   = false;
        this.render         = this.render.bind(this);


        this.ticker = new Ticker();
        this.ticker.on('tick', this.render);
        this.ticker.on('benchmark', () => {
            console.log('benchmark', this.ticker.averageFps);
        });

        this.copyPass = new ShaderPass(CopyShader);
    }

    add(item) {
        // if(item instanceof Composition)
        //     this.addComposition(item);
        this.scene.add(item);
    }

    remove(item){
        this.scene.remove(item);
    }


    // addComposition(item){
    //     item.assemble();
    //     this.passes.set(item, item.blendPass);
    // }

    compose(){
        this.composer.passes = [];
        if(!this.disableRenderPass){

            const color = new Color();
            this.renderer.getClearColor(color)
            const renderPass = new RenderPass(this.scene, this.camera, null, color, this.renderer.getClearAlpha());
            this.composer.addPass(renderPass);
        }
        this.passes.forEach((pass, item) => !item.visible || this.composer.addPass(pass));
        this.customPasses.forEach(pass => this.composer.addPass(pass));
        this.composer.addPass(this.copyPass);
    }

    get domElement() {
        return this.renderer.domElement;
    }

    get background(){
        return this._background;
    }
    set background(background){
        this._background = background;
        this.scene.background = background.texture;
    }

    setSize(width, height) {
        this.width  = width;
        this.height = height;
        this.camera.aspect = width / height;

        this.camera.updateProjectionMatrix();
        this.customPasses.forEach(pass => {
            pass.setSize ? pass.setSize(width, height):null; 
        });
        // this.items.forEach(item => !item.resize || item.resize(width, height));
        this.renderer.setSize(width, height);
        this.composer.setSize(width, height);
        this.requestRender();
    }

    requestRender = () => {
        this.ticker.requestRender();
    }

    render(delta) {
        this.time += delta;
        this.deltaTime = delta;
        this.lastTime = this.time;

        if(this.background) this.background.update(this.time, delta);
        // this.items.forEach(item => !item.visible || item.update(this.time, delta));
        // this.composer.render(delta);
        this.renderer.render(this.scene, this.camera);
    }

    destroy(){
        this.renderer.dispose();
    }

    start() {
        this.ticker.start();
        this.requestRender();
    }

    stop() {
        this.ticker.stop();
    }
}
