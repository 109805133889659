import { ModelViewer } from './ModelViewer';
import { DropzonePlugin } from './plugins/DropzonePlugin';
import { UrlParamsPlugin } from './plugins/UrlParamsPlugin';
import {
	GlobalsPlugin,
	LightingPlugin,
	VariantsPlugin,
	LoadModelPlugin,
	DebugPlugin,
	InfosPlugin
} from 'js/viewer/plugins/';

const viewer = new ModelViewer({plugins:[
	InfosPlugin,
	DebugPlugin,
	DropzonePlugin,
	UrlParamsPlugin
]});

document.querySelector('.viewer').appendChild(viewer.domElement);
window.addEventListener('resize', viewer.resize);

const loader 		 = document.getElementById('loader');
const loaderProgress = loader.querySelector('.loader-progress');

viewer.on('progress', (progress) => { 
	if(progress == 0) gsap.to(loader, 0.5, {opacity:1, delay:0.1});
	gsap.to(loaderProgress, 1, {x:loader.offsetWidth*progress, ease:'expo.out'});
	if(progress == 1) gsap.to(loader, 0.5, {opacity:0, delay:0.5});
});

(async () => {
	viewer.rendering.camera.position.set(0, 5, 10)
	await viewer.attach();

	viewer.resize();
	await viewer.update();
	viewer.start();

	viewer.plugins.get(DebugPlugin).debug(
		GlobalsPlugin,
		LightingPlugin,
		VariantsPlugin,
		DropzonePlugin,
		UrlParamsPlugin,
		InfosPlugin
	);

	viewer.plugins.get(VariantsPlugin).on('loaded', viewer.plugins.get(DebugPlugin).refresh)

})();