import { Plugin, ObjectPlugin } from './';
import { RenderingHelper, PMREMHelper, TextureHelper } from '../helpers';
import { Material, Texture, CubeTexture, Vector3, WebGLCubeRenderTarget, LinearFilter, LinearMipmapLinearFilter, ShaderChunk } from 'three';

const initialToneMappingParsFragment = ShaderChunk.tonemapping_pars_fragment + '';

let _toneMappingWhitePoint = 1;
const updateToneMappingWhitepoint = (toneMappingWhitePoint = 1) => {
	ShaderChunk.tonemapping_pars_fragment = initialToneMappingParsFragment.replace(
		'vec3 CustomToneMapping( vec3 color ) { return color; }',
		`#define Uncharted2Helper( x ) max( ( ( x * ( 0.15 * x + 0.10 * 0.50 ) + 0.20 * 0.02 ) / ( x * ( 0.15 * x + 0.50 ) + 0.20 * 0.30 ) ) - 0.02 / 0.30, vec3( 0.0 ) )
		float toneMappingWhitePoint = ${toneMappingWhitePoint.toFixed(2)};
		vec3 CustomToneMapping( vec3 color ) {
			color *= toneMappingExposure;
			return saturate( Uncharted2Helper( color ) / Uncharted2Helper( vec3( toneMappingWhitePoint ) ) );
		}`
	);

	_toneMappingWhitePoint = toneMappingWhitePoint;
}

updateToneMappingWhitepoint(10);

// Force material update when changing whitepoint
Material.prototype.customProgramCacheKey = function() {
	return _toneMappingWhitePoint + '';
}

export class GlobalsPlugin extends Plugin{
	constructor(manager){
		super(manager, 'GlobalsPlugin');

		this.rendering 	= this.viewer.rendering;
		this.renderer 	= this.viewer.rendering.renderer;

		this._usePMREM = true;
		this._antialias = false;
		this._wireframe = false;

		this.outputEncoding = "srgb";
		
	}


	update(){
		// const config  = this.viewer.configuration.globals;
		// const assets  = this.manager.get(AssetsPlugin).assets;

		// this.toneMapping 			 	= typeof config.toneMapping == "string" 			? config.toneMapping : "Linear";
		// this.toneMappingExposure 	 	= typeof config.toneMappingExposure == "number" 	? config.toneMappingExposure : 1;
		// this.toneMappingWhitepoint 	 	= typeof config.toneMappingWhitepoint == "number" ? config.toneMappingWhitepoint : 1;
		// this.gammaFactor 				= typeof config.gammaFactor == "number" ? config.gammaFactor : 2;
		// this.physicallyCorrectLights 	= typeof config.physicallyCorrectLights == "boolean" ? config.physicallyCorrectLights : false;
		// this.antialias 					= typeof config.antialias == "boolean" ? config.antialias : false;
		// this.camera 					= typeof config.camera == "object" ? config.camera : null;
		// this.usePMREM 	= typeof config.usePMREM == "boolean" ? config.usePMREM : false;
		// this.envMap 	= typeof config.envMap == "string" ? assets.get(config.envMap) : null;
	}
	
	get toneMapping(){
		return RenderingHelper.getToneMappingName(this.renderer.toneMapping);
	}
	set toneMapping(type){
		this.renderer.toneMapping = RenderingHelper.getToneMappingValue(type);
	    this.rendering.scene.traverse( obj => {
	        if(obj.isMesh && obj.material)
	            obj.material.needsUpdate = true;
	    });
		this.rendering.requestRender();
		this.emit('change');
	}
		
	get toneMappingExposure(){
		return this.renderer.toneMappingExposure;
	}
	set toneMappingExposure(toneMappingExposure){
		this.renderer.toneMappingExposure = toneMappingExposure;
		this.rendering.requestRender();
		this.emit('change');
	}

	get toneMappingWhitepoint(){
		return _toneMappingWhitePoint;
	}
	set toneMappingWhitepoint(toneMappingWhitepoint){
		updateToneMappingWhitepoint(toneMappingWhitepoint);
		this.viewer.rendering.scene.traverse(object => {
		    if(object.type === 'Mesh'){
		    	object.material.needsUpdate = true;
		    }
		});
		this.rendering.requestRender();
		this.emit('change');
	}

	get outputEncoding(){
		return TextureHelper.getEncodingFromValue(this.renderer.outputEncoding);
	}
	set outputEncoding(outputEncoding){
		let encoding = TextureHelper.getEncoding(outputEncoding);
		this.rendering.composer.renderTarget1.texture.encoding = encoding;
		this.rendering.composer.renderTarget2.texture.encoding = encoding;
		this.renderer.outputEncoding = TextureHelper.getEncoding(outputEncoding);
		this.rendering.requestRender();
		this.emit('change');
	}

	get gammaFactor(){
		return this.rendering.renderer.gammaFactor;
	}
	set gammaFactor(gammaFactor){
		this.rendering.renderer.gammaFactor = gammaFactor;
		this.rendering.requestRender();
		this.emit('change');
	}

	get wireframe(){
		return this._wireframe;
	}
	set wireframe(wireframe){
		this.viewer.rendering.scene.traverse(mesh => {
			if(mesh.isMesh) mesh.material.wireframe = wireframe;
		})

		this._wireframe = wireframe;
		this.rendering.requestRender();
		this.emit('change');
	}

	get physicallyCorrectLights(){
		return this.renderer.physicallyCorrectLights;
	}

	set physicallyCorrectLights(physicallyCorrectLights){
		this.renderer.physicallyCorrectLights = physicallyCorrectLights;
		this.rendering.requestRender();
		this.emit('change');
	}

	get usePMREM(){
		return this._usePMREM;
	}
	set usePMREM(usePMREM){
		this._usePMREM = usePMREM;

		this.envMap = this._envMap;
		this.background = this._background;
		this.rendering.requestRender();
		this.emit('change');
	}

	get envMap(){
		return this._envMap;
	}
	set envMap(envMap){
		this._envMap = envMap;

		this.rendering.scene.environment = envMap ? this.getTexture(envMap, this.usePMREM) : null;
		this.rendering.requestRender();

		this.emit('envMap');
		this.emit('change');
	}

	get background(){
		return this._background;
	}
	set background(background){
		this._background = background;
		this.rendering.scene.background = background ? this.getTexture(background, false) : null;
		this.rendering.requestRender();
		this.emit('background');
		this.emit('change');
	}

	get antialias(){
		return this._antialias;
	}
	set antialias(antialias){
		if(this._antialias != antialias){
			this._antialias = antialias;
			this.renderer.setPixelRatio(antialias ? 1.5 : Math.min(1, window.devicePixelRatio));
			this.rendering.composer.setPixelRatio(antialias ? 1.5 : Math.min(1, window.devicePixelRatio));
		}
		this.rendering.requestRender();
		this.emit('change');
	}

	getTexture(texture, usePMREM = false){
		if(texture instanceof CubeTexture){
			if(usePMREM)
				return PMREMHelper.generateCube(this.renderer, texture);
			return texture;
		}
		if(texture instanceof Texture){
			const rt = new WebGLCubeRenderTarget(1024);
				  rt.fromEquirectangularTexture(this.renderer, texture);

			if(usePMREM)
				return PMREMHelper.generateCube(this.renderer, rt.texture);

			return rt.texture;
		}
	}

	debug(gui, debug){
		
		gui.add(this, 'toneMapping', {
			'None': 'None',
			'Linear': 'Linear',
			'Reinhard': 'Reinhard',
			'Cineon': 'Cineon',
			'ACESFilmic': 'ACESFilmic',
			'Uncharted2': 'Custom'
		}).onChange(() => {
			this.requestRender();
			debug.refresh()
		});
		
		if(this.toneMapping != "None")
			gui.add(this, 'toneMappingExposure', 0.01, 10, 0.01).name('Exposure').onChange(this.requestRender);

		if(this.toneMapping == "Custom")
			gui.add(this, 'toneMappingWhitepoint', 0.01, 10, 0.01).name('Whitepoint').onChange(this.requestRender);

		let gammaController = null;
		gui.add(this, 'outputEncoding', {
			'LinearEncoding': 'linear',
			'sRGBEncoding': 'srgb',
			'GammaEncoding': 'gamma',
			'RGBEEncoding': 'rgbe',
			'LogLuvEncoding': 'logluv',
			'RGBM7Encoding': 'rgbm7',
			'RGBM16Encoding': 'rgbm16',
			'RGBDEncoding': 'rgbd'
		}).onChange(() => {
			this.requestRender();
			debug.refresh()
		});

		if(this.outputEncoding == "gamma"){
			gui.add(this, 'gammaFactor', 0.5, 5, 0.01).onChange(() => {
				this.viewer.rendering.scene.traverse(mesh => {
					if(mesh.isMesh)
						mesh.material.needsUpdate = true;
				})
				this.requestRender();
			});
		}

		gui.add(this, 'wireframe').name('Wireframe').onChange(this.requestRender);
		gui.add(this, 'antialias').name('Antialias (Upscale x1.5)').onChange(this.requestRender);
	}
}
