import { 
	Viewer,
	LightingPlugin,
	GlobalsPlugin,
	LoadModelPlugin,
	OrbitControlsPlugin,
	VariantsPlugin
} from 'js/viewer';


export class ModelViewer extends Viewer{
	constructor(options){

		if(!options.plugins)
			options.plugins = [];

		options.plugins.push(
			LightingPlugin,
			LoadModelPlugin,
			GlobalsPlugin,
			OrbitControlsPlugin,
			VariantsPlugin
		);

		super(options);

		this._previousModel = null;
	}

	async attach(){
		super.attach();

		this.plugins.get(LoadModelPlugin).on('loaded', this.onLoaded);
	}

	detach(){
		super.detach();

		this.plugins.get(LoadModelPlugin).off('loaded', this.onLoaded);
	}

	onLoaded = ({scene}) => {

		if(this._previousModel)
			this.remove(this._previousModel);
		this.add(scene);
		this.requestRender();

		this._previousModel = scene
	}

}

