import { flatten } from 'array-flatten';

export class PluginManager{
	constructor(viewer, plugins){
		this.viewer  = viewer;
		this.plugins = new Map();

		this.add(...plugins);
	}

	add(...pluginClasses){
		pluginClasses = flatten(pluginClasses);
		//Creates defined plugins
		pluginClasses.forEach(moduleClass => this.plugins.set(moduleClass, new moduleClass(this)));

		//Create dependencies
		this.plugins.forEach(module => {
			module.dependencies.forEach(dependency => {
				if(!this.plugins.has(dependency))
					this.plugins.set(dependency, new dependency(this));
			});
		});
		
		this.plugins = new Map(Array.from(this.plugins.entries()).sort(([keyA, moduleA], [keyB, moduleB]) => {
			if(moduleA.dependencies.has(keyB)) return 1;
			if(moduleB.dependencies.has(keyA)) return -1;
			return moduleB.priority - moduleA.priority;
		}));
	}

	get(moduleClass){
		return this.plugins.get(moduleClass);
	}

	async update(){
		let promises = [];
		for(let [clazz, plugin] of Array.from(this.plugins)){
			promises.push(plugin.preload());
		}
		await Promise.all(promises);

		promises = [];
		for(let [clazz, plugin] of Array.from(this.plugins)){
			promises.push(plugin.update());
		}
		await Promise.all(promises);
	}
	
	async attach(){
		let promises = [];
		for(let [name, plugin] of this.plugins){
			promises.push(plugin.attach());
		}
		await Promise.all(promises);
	}
	async detach(){
		let promises = [];
		for(let [name, plugin] of this.plugins){
			promises.push(plugin.detach());
		}
		await Promise.all(promises);
	}

}
