import { Plugin } from './Plugin';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export class OrbitControlsPlugin extends Plugin{
	constructor(manager){
		super(manager);

		this.create();
	}

	create(){
		this.controls = new OrbitControls(this.viewer.rendering.camera, this.viewer.domElement);
		this.controls.enableDamping = true;
		this.controls.dampingFactor = 0.05;
		this.controls.minDistance = 0.1;
		this.controls.maxDistance = 1000;
	}

	rebuild(){
		this.detach();

		if(this.controls){
			this.controls.dispose();
			this.create();
		}

		this.attach();
	}

	attach(){
		this.viewer.rendering.ticker.on('tick', this.render);
		this.controls.addEventListener('start', this.viewer.requestRender);
		this.controls.addEventListener('change', this.viewer.requestRender);
		this.controls.addEventListener('end', this.viewer.requestRender);
	}

	detach(){
		this.viewer.rendering.ticker.off('tick', this.render);
		this.controls.removeEventListener('start', this.viewer.requestRender);
		this.controls.removeEventListener('change', this.viewer.requestRender);
		this.controls.removeEventListener('end', this.viewer.requestRender);
	}

	render = () => {
		this.controls.update();
	}
}