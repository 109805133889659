import { Plugin } from './Plugin';
import { LoadModelPlugin } from './LoadModelPlugin';
import { VariantsPlugin } from './VariantsPlugin';

export class InfosPlugin extends Plugin{
	constructor(manager){
		super(manager, 'InfosPlugin');

		this.dependencies.add(LoadModelPlugin);
		this.dependencies.add(VariantsPlugin);
	}

	debug(gui, debug){
		const info = this.viewer.rendering.renderer.info;
		if(info){
			const r = gui.addFolder('render')
			r.add(info.render, 'calls').listen();
			r.add(info.render, 'frame').listen();
			r.add(info.render, 'points').listen();
			r.add(info.render, 'lines').listen();
			r.add(info.render, 'triangles').listen();

			const m = gui.addFolder('memory')
			m.add(info.memory, 'geometries').listen();
			m.add(info.memory, 'textures').listen();
		}
	}
}